import { OtherFiltersMapping } from '@leapfinance/frontend-commons/components/geebee/cf/utils';
import { type ICheckboxProps } from '@leapfinance/frontend-commons/components/geebee/inputs/Checkbox';
import { isOptionType } from '@leapfinance/frontend-commons/components/geebee/inputs/DropDown';
import { typography } from '@leapfinance/frontend-commons/components/geebee/theme';
import { type OPTION_TYPE } from '@leapfinance/frontend-commons/schema/common';
import ExcelJS from 'exceljs';
import { type FormApi } from 'final-form';
import createDecorator from 'final-form-calculate';
import { startCase, toLower } from 'lodash';

import { store } from '@/app/store';
import { transformOptionValues } from '@/components/StudentProfileSection/helpers';
import { Courses } from '@/features/courseFinder/cfStateSlice';
import { CFViewCourseType } from '@/types/courseFinder';
import { definitions } from '@/types/schema';
import {
  CspCollegeSuggestionResponseType,
  GetSuggestionsDataPathRequestType,
  GetSuggestionsDataPathResponseType,
  GetUniversititesResponseType,
} from '@/types/Services';
import { GRADING_SCHEME } from '@/utils/helperFunctions';

import { CFEventsType, CfEventUtil } from '../CFEventsUtil';

import { InsertionConfigType } from './CfCoursesDashabord/CourseCardWrapper';

import { CFCoursesCSVHeader, CSVKEYS } from './constants';
import DegreeIntakeUtil from './DegreeCountryUtil';

export interface BaseFilterProps {
  handleFilterApply: (data: {
    name: CFCourseRequestDtoKeys | CFFormHelperKeys;
    value: any;
    prevValue?: any;
    forceApply?: boolean;
  }) => void;
}
export type FilterAggregation = Exclude<
  SuggestionResponse[`aggregations`],
  undefined
>[number];
export type SingleCFCourse = Exclude<
  SuggestionResponse[`courseList`],
  undefined
>[number];
export type CFFilterPayload = Record<
  keyof GetSuggestionsDataPathRequestType[`courseFinderRequestDto`],
  any
> &
  Record<DestinationFormKeys, any> & {
    highlightEligiblityFilters?: boolean;
    tuitionFees?: number[];
  };

export type SuggestionResponse = Exclude<
  GetSuggestionsDataPathResponseType[`data`],
  undefined
>;

export type CFCourse = Exclude<
  SuggestionResponse[`courseList`],
  undefined
>[number];

export type DestinationTags =
  | 'City'
  | 'State'
  | 'Country'
  | 'Institute'
  | 'Country Group';
export type DestinationFormKeys =
  | 'destinationCity'
  | 'destinationState'
  | 'destinationCountry'
  | 'destinationInstitute'
  | 'studentCurrency'
  | 'studyWhereTag'
  | 'studyWhereTagId';
export type GlobalFilterKeys =
  | keyof Pick<
      definitions['CourseFinderRequestDto'],
      'collegeIds' | 'degreeIdsV2' | 'intake' | 'countryIds'
    >
  | 'tutionFees';

export type CFCourseRequestDtoKeys =
  keyof definitions[`CourseFinderRequestDto`];

export type CFFormHelperKeys =
  | `destination`
  | `tagsRawSearch`
  | `tuitionFees`
  | `courseDuration`
  | `studentCurrency`
  | `minCourseFeeInInr`
  | `maxCourseFeeInInr`;

export type CFFormType = CFFilterPayload & Record<CFFormHelperKeys, any>;
export const CFDashboardId = `course-finder-dashbaord`;
export const checkboxFormLabelProps: ICheckboxProps[`formControlLabelProps`] = {
  componentsProps: {
    typography: {
      style: {
        fontSize: typography.body2?.fontSize,
        fontFamily: typography.body2?.fontFamily,
        lineHeight: typography.body2?.lineHeight,
        fontWeight: typography.body2?.fontWeight,
      },

      className: `text-grey-800`,
    },
  },
};

export const defaultCfFilters:
  | definitions['CourseFinderRequestDto']
  | Record<CFFormHelperKeys, any> = {
  sortBy: `POPULARITY`,
  sortOrder: `DESC`,
  studentPreferenceToggleOn: false,
  minimumShouldMatch: 0,
  pageIndex: 1,
  selectedFilter: [],
  courseDurationMax: 60,
  courseDurationMin: 0,
  courseDuration: [0, 60],
};

export const getFilterOptions = (
  options: Exclude<
    definitions['CourseFinderResponseDto'][`aggregations`],
    undefined
  >[number][`options`],
): OPTION_TYPE[] => {
  if (!options) return [];
  const validaOptions = options.filter(
    (option) =>
      typeof option?.title !== `undefined` &&
      typeof option?.id !== `undefined` &&
      option?.title !== null &&
      option?.id !== null,
  );
  return validaOptions.map((option) => ({
    ...option,
    defaultLabel: option.label,
    label: option?.title,
    value: option?.id,
  })) as OPTION_TYPE[];
};

export const transformCountryIds = (
  countryIds: any,
  key: 'country' | 'countryGroup',
) => {
  return countryIds
    ?.filter((countryId: any) => {
      if (typeof countryId === `number`) {
        return true;
      }
      return countryId?.split(`-`)?.[0] === key;
    })
    ?.map((countryId: any) => {
      if (typeof countryId === `number`) {
        return countryId;
      }
      return Number(countryId?.split(`-`)?.[1]);
    });
};

export const getCFPayload = (
  form?: FormApi<CFFilterPayload>,
  newValues?: any,
  studentCurrency?: definitions[`CurrenciesResponseDto`] & OPTION_TYPE,
) => {
  const values = form?.getState()?.values ?? newValues ?? {};

  const transformedValues: CFFilterPayload = transformOptionValues({
    ...values,
  });

  const globalMax = roundUpToNextNearestThousand(
    store.getState().courseFinderSlice.globalFilterValues.maxCourseFeeInINR /
      (values?.studentCurrency?.conversionRate ?? 1),
  );
  const globalMin = roundDownToNextNearestThousand(
    store.getState().courseFinderSlice.globalFilterValues.minCourseFeeInINR /
      (values?.studentCurrency?.conversionRate ?? 1),
  );

  const maxTuitionFeesChanged = globalMax !== transformedValues?.tuitionFeesMax;
  const minTuitionFeesChanged = transformedValues?.tuitionFeesMin !== globalMin;

  const maxDurationChanged = transformedValues?.courseDurationMax !== 60;
  const minDurationChanged = transformedValues?.courseDurationMin !== 0;

  const countryGroupIdsLocal = transformCountryIds(
    transformedValues?.countryIds,
    `countryGroup`,
  );

  const countryIdsLocal = transformCountryIds(
    transformedValues?.countryIds,
    `country`,
  );

  const body: CFFilterPayload = {
    ...transformedValues,
    countryIds: countryIdsLocal,
    countryGroupIds: [
      ...(transformedValues?.studyWhereTag === `Country Group`
        ? [transformedValues?.studyWhereTagId]
        : []),
      ...(countryGroupIdsLocal ?? []),
    ],
    intake: (transformedValues?.intake ?? [])
      .map((data: string) => JSON.parse(data ?? {}))
      .flat(),
    tuitionFeesMax:
      maxTuitionFeesChanged || minTuitionFeesChanged
        ? (transformedValues?.tuitionFeesMax ?? 10000000) *
          (values?.studentCurrency?.conversionRate ?? 1)
        : null,
    tuitionFeesMin:
      minTuitionFeesChanged || maxTuitionFeesChanged
        ? (transformedValues?.tuitionFeesMin ?? 0) *
          (values?.studentCurrency?.conversionRate ?? 1)
        : null,
    courseDurationMax:
      maxDurationChanged || minDurationChanged
        ? transformedValues.courseDurationMax
        : null,
    courseDurationMin:
      minDurationChanged || maxDurationChanged
        ? transformedValues.courseDurationMin
        : null,
  };

  return body;
};

export const CFDecorator = createDecorator(
  {
    field: `destination`,
    updates: (value: any): any => {
      if (isFalsy(value)) return {};
      const tag = value?.tag as DestinationTags;
      const destinationFormvalues = {
        destinationCity: undefined,
        destinationCountry: undefined,
        destinationInstitute: undefined,
        destinationState: undefined,
        collegeIds: undefined,
        cityIds: undefined,
        stateId: undefined,
        countryIds: undefined,
        studyWhereTagId: undefined,
        studyWhereTag: undefined,
      } as Record<DestinationFormKeys | CFCourseRequestDtoKeys, any>;

      switch (tag) {
        case `City`:
          destinationFormvalues[`destination${tag}`] = value?.value;
          destinationFormvalues[`cityIds`] = [
            { label: value?.label, value: value?.value },
          ];

          break;
        case `Country`:
        case `Country Group`:
          // @ts-ignore
          destinationFormvalues[`destination${tag}`] = value?.value;
          destinationFormvalues[`countryIds`] = [
            {
              label: value?.label,
              value:
                tag === `Country`
                  ? `country-${value?.value}`
                  : `countryGroup-${value?.value}`,
            },
          ];
          break;
        case `Institute`:
          destinationFormvalues[`destination${tag}`] = value?.value;
          destinationFormvalues[`collegeIds`] = [
            { label: value?.label, value: value?.value },
          ];
          break;
        case `State`:
          destinationFormvalues[`destination${tag}`] = value?.value;
          destinationFormvalues[`stateId`] = [
            { label: value?.label, value: value?.value },
          ];
          break;

        default:
          break;
      }

      destinationFormvalues[`studyWhereTagId`] = value?.value;
      destinationFormvalues[`studyWhereTag`] = tag;
      DegreeIntakeUtil.getInstance().fetchAndSetMapping({
        entities: [
          {
            entityId: value?.value,
            entityType: tag,
          },
        ],
      });
      return destinationFormvalues;
    },
  },
  {
    field: `tuitionFees` as CFFormHelperKeys,
    updates: (value: any, field: string, allValues?: any) => {
      if (!value) return {};
      if (allValues?.isGlobalSearch) return { isGlobalSearch: false };
      const isMinEqual = value?.[0] === allValues?.tuitionFeesMin;
      const isMaxEqual = value?.[1] === allValues?.tuitionFeesMax;
      if (isMinEqual && isMaxEqual) return {};

      return {
        tuitionFeesMax: Number(value?.[1]),
        tuitionFeesMin: Number(value?.[0]),
      } as unknown as CFFilterPayload;
    },
  },
  {
    field: `tuitionFeesMin` as CFCourseRequestDtoKeys,
    updates: (value: any, field: string, allValues?: any) => {
      if (typeof value === `undefined` || isFalsy(allValues?.tuitionFeesMax))
        return {};
      if (allValues?.isGlobalSearch) return { isGlobalSearch: false };
      const min = Number(value);

      return {
        tuitionFees: [min, allValues?.tuitionFeesMax],
      };
    },
  },
  {
    field: `tuitionFeesMax` as CFFormHelperKeys,
    updates: (value: any, field: string, allValues?: any) => {
      if (typeof value === `undefined` || isFalsy(allValues?.tuitionFeesMin))
        return {};
      if (allValues?.isGlobalSearch) return { isGlobalSearch: false };
      const max = Number(value);

      return {
        tuitionFees: [allValues?.tuitionFeesMin, max],
      };
    },
  },
  {
    field: `courseDuration` as CFFormHelperKeys,
    updates: (value: any, field: string, allValues?: any) => {
      if (!value) return {};
      const isMinEqual =
        value?.[0] === (allValues as CFFilterPayload)?.courseDurationMin;
      const isMaxEqual =
        value?.[1] === (allValues as CFFilterPayload)?.courseDurationMax;
      if (isMinEqual && isMaxEqual) return {};

      return {
        courseDurationMax: Number(value?.[1] ?? 60),
        courseDurationMin: Number(value?.[0] ?? 0),
      } as unknown as CFFilterPayload;
    },
  },
  {
    field: `courseDurationMin` as CFCourseRequestDtoKeys,
    updates: (value: any, field: string, allValues?: any) => {
      if (typeof value === `undefined`) return {};
      const min = Number(value);
      if (
        min ===
          (allValues as Record<CFFormHelperKeys, any>)?.courseDuration?.[0] ??
        0
      ) {
        return {};
      }
      return {
        courseDuration: [Number(min), Number(allValues?.courseDurationMax)],
      } as Record<CFFormHelperKeys, any>;
    },
  },
  {
    field: `courseDurationMax` as CFFormHelperKeys,
    updates: (value: any, field: string, allValues?: any) => {
      if (typeof value === `undefined`) return {};
      const max = Number(value);
      if (
        max ===
          (allValues as Record<CFFormHelperKeys, any>)?.courseDuration?.[1] ??
        60
      ) {
        return {};
      }
      return {
        courseDuration: [Number(allValues?.courseDurationMin), Number(max)],
      } as Record<CFFormHelperKeys, any>;
    },
  },

  {
    field: `studentCurrency` as any,
    updates: (value: any, field: string, allValues?: any) => {
      if (typeof value === `undefined` || allValues?.skipTuitionFeesCalculation)
        return {};
      if (allValues?.isGlobalSearch) return { isGlobalSearch: false };
      const conversionRate = value?.conversionRate;

      if (isFalsy(conversionRate) || typeof conversionRate !== `number`)
        return {};
      return getTutitionFeesValues(conversionRate);
    },
  },
  {
    field: `maxCourseFeeInInr` as any,
    updates: (value: any, field: string, allValues?: any) => {
      if (typeof value === `undefined`) return {};
      if (allValues?.isGlobalSearch) return { isGlobalSearch: false };
      const conversionRate = allValues?.studentCurrency?.conversionRate;
      if (!conversionRate) return {};
      if (
        typeof allValues?.minCourseFeeInInr === `number` &&
        typeof allValues?.maxCourseFeeInInr === `number`
      ) {
        const minTuitionFeesInLocalCurrency = Math.round(
          allValues?.minCourseFeeInInr / conversionRate,
        );
        const maxTuitionFeesInLocalCurrency = Math.round(
          allValues?.maxCourseFeeInInr / conversionRate,
        );
        const convertedMinFees = roundDownToNextNearestThousand(
          minTuitionFeesInLocalCurrency,
        );
        const convertedMaxFees = roundUpToNextNearestThousand(
          maxTuitionFeesInLocalCurrency,
        );
        return {
          tuitionFeesMin: convertedMinFees,
          tuitionFeesMax: convertedMaxFees,
          tuitionFees: [convertedMinFees, convertedMaxFees],
        } as unknown as CFFilterPayload;
      } else {
        return {};
      }
    },
  },
);

export const getTutitionFeesValues = (conversionRate?: number) => {
  const globalFilterValues =
    store.getState().courseFinderSlice.globalFilterValues;
  const minTuitionFeesInLocalCurrency =
    globalFilterValues?.minCourseFeeInINR && conversionRate
      ? Math.round(globalFilterValues?.minCourseFeeInINR / conversionRate)
      : 0;
  const maxTuitionFeesInLocalCurrency =
    globalFilterValues?.maxCourseFeeInINR && conversionRate
      ? Math.round(globalFilterValues?.maxCourseFeeInINR / conversionRate)
      : 0;
  const convertedMinFees = roundDownToNextNearestThousand(
    minTuitionFeesInLocalCurrency,
  );
  const convertedMaxFees = roundUpToNextNearestThousand(
    maxTuitionFeesInLocalCurrency,
  );
  return {
    tuitionFeesMin: convertedMinFees,
    tuitionFeesMax: convertedMaxFees,
    tuitionFees: [convertedMinFees, convertedMaxFees],
  };
};

export const isFalsy = (value: any) => {
  return (
    value === `` ||
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === `object` && Object.keys(value).length === 0)
  );
};

export const showHighOfferChangesOptions = [
  { label: `High offer Institute`, value: true },
];

export const tutionFeesMarks = (
  minValue: number,
  maxValue: number,
  currencyCode: string,
) =>
  [minValue, maxValue].map((value) => ({
    label: convertInputToReadableCurrencyFormat(value, currencyCode),
    value: value,
  }));

type OfferRate = Exclude<
  Exclude<
    Exclude<
      CspCollegeSuggestionResponseType[`data`],
      undefined
    >[`universities`],
    undefined
  >[number][`gbOfferRate`],
  undefined
>;

export type CfUniversitiesType = Exclude<
  Exclude<GetUniversititesResponseType, undefined>[`data`],
  undefined
>[`universityList`];

export const getSortedUniversityFilters = (
  form: FormApi<CFFormType>,
  options: Exclude<
    definitions['CourseFinderResponseDto'][`aggregations`],
    undefined
  >[number][`options`],
) => {
  // sort order
  // 1. Selected values
  // 2. High offer institute
  // 3. rest options

  const fixedOptions = getFilterOptions(options);
  const collegeIds = (form.getState()?.values?.collegeIds ?? []).map(
    (op: OPTION_TYPE) => op?.value,
  );

  fixedOptions.sort((o1, o2) => {
    const priorityOrder: Record<OfferRate, number> = {
      PREFERRED_INSTITUTE: 0,
      MODERATE_OFFER_PROBABILITY: 1,
      LOW_OFFER_PROBABILITY: 2,
    };
    if (!o1?.defaultLabel) {
      return 1;
    }

    if (!o2?.defaultLabel) {
      return -1;
    }

    return (
      priorityOrder[o1?.defaultLabel as OfferRate] -
      priorityOrder[o2?.defaultLabel as OfferRate]
    );
  });

  return [
    ...fixedOptions.filter((op) => collegeIds.includes(op?.value)),
    ...fixedOptions.filter((op) => !collegeIds.includes(op?.value)),
  ];
};

export const scrollToCardWrapperTop = () => {
  const element = document.getElementById(CFDashboardId);
  if (element) {
    element.scrollIntoView({ behavior: `smooth`, block: `start` });
  }
};

export const generateCourseExcel = async (
  courses: Exclude<SuggestionResponse[`courseList`], undefined>,
  fileName: string,
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`Sheet1`);

  worksheet.columns = CFCoursesCSVHeader;

  courses.forEach((course) => {
    const row: any = {};
    CSVKEYS.forEach((key) => {
      let value = (course as any)[key];
      if (Array.isArray(value)) {
        value = value.join(`, `);
      } else if (typeof value === `object` && value !== null) {
        value = JSON.stringify(value);
      } else if (typeof value === `boolean`) {
        value = value ? `Yes` : `No`;
      }
      row[key] = value;
    });

    worksheet.addRow(row);
  });

  worksheet.eachRow(function (row) {
    row.height = 22;
    row.eachCell(function (cell, colNumber) {
      row.getCell(colNumber).font = { size: 12, name: `Helvetica` };
      row.getCell(colNumber).border = {
        top: { color: { argb: `000000` } },
        left: { color: { argb: `000000` } },
        bottom: { color: { argb: `000000` } },
        right: { color: { argb: `000000` } },
      };
    });
  });

  worksheet.getRow(1).fill = {
    type: `pattern`,
    pattern: `solid`,
    fgColor: { argb: `DCDCDC` },
  };

  worksheet.getRow(1).font = {
    bold: true,
    size: 12,
    name: `Helvetica`,
  };

  worksheet.columns.forEach((column) => {
    const lengths = column?.values?.map((v) => (v ?? ``).toString().length) ?? [
      0,
    ];
    const maxLength = Math.max(...lengths.filter((v) => typeof v === `number`));
    column.width = maxLength + 4;
  });
  // Create a blob containing the Excel file data
  const excelBlob = await workbook.xlsx.writeBuffer();

  // Create a download link and trigger the download
  const downloadLink = URL.createObjectURL(
    new Blob([excelBlob], {
      type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`,
    }),
  );
  const a = document.createElement(`a`);
  a.href = downloadLink;
  a.download = `${fileName}.xlsx`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const coursesPdfDownloader = (
  courses: Exclude<SuggestionResponse[`courseList`], undefined>,
  student?: OPTION_TYPE | null,
) => {
  const courseIds = courses?.map((course) => course?.courseId);
  console.log(`courseIds>>>`, courseIds);
  console.log(`student>>>`, student);
  if (student?.label || student?.value) {
    const studentName = student?.label?.split(`-`)?.[0];
    const redirectionData = {
      courseIds: courseIds,
      studentId: student?.value,
      studentName: studentName,
      hostName: window?.location?.host?.split(`.`)[0],
    };
    window?.open(
      `/course-pdf?courseDetails=${window.btoa(
        JSON.stringify(redirectionData),
      )}&hostName=${window.location.host.split(`.`)[0]}`,
      `_blank`,
    );
  } else {
    const redirectionData = {
      courseIds: courseIds,
      hostName: window?.location?.host?.split(`.`)[0],
    };
    const stringified = JSON.stringify(redirectionData);
    const encoded = window.btoa(stringified);

    window?.open(`/course-pdf?courseDetails=${encoded}`, `_blank`);
  }
};

export const triggerSideBarEvents = (
  name: any,
  value: any,
  source?: string,
) => {
  if (
    (
      [
        `collegeIds`,
        `cityIds`,
        `stateId`,
        `tuitionFees`,
        `courseDuration`,
        `intakeStatus`,
        `showOnlyPreferredInstitutes`,
        `countryIds`,
        `degreeIdsV2`,
      ] as (CFCourseRequestDtoKeys | CFFormHelperKeys)[]
    ).includes(name)
  ) {
    switch (name as CFCourseRequestDtoKeys | CFFormHelperKeys) {
      case `collegeIds`:
        CfEventUtil.triggerCfEvent(CFEventsType.UNIVERSITY, {
          restInfo: { source },
        });
        break;
      case `cityIds`:
        CfEventUtil.triggerCfEvent(CFEventsType.CITY, { restInfo: { source } });
        break;
      case `stateId`:
        CfEventUtil.triggerCfEvent(CFEventsType.STATE, {
          restInfo: { source },
        });
        break;
      case `degreeIdsV2`:
        CfEventUtil.triggerCfEvent(CFEventsType.DEGREE, {
          restInfo: { source },
        });
        break;
      case `countryIds`:
        CfEventUtil.triggerCfEvent(CFEventsType.COUNTRY, {
          restInfo: { source },
        });
        break;
      case `tuitionFees`:
        CfEventUtil.triggerCfEvent(CFEventsType.TUITION_FEES, {
          name: `${value?.[0] * 100000} to ${value?.[1] * 100000}`,
          restInfo: { source },
        });
        break;
      case `courseDuration`:
        CfEventUtil.triggerCfEvent(CFEventsType.DURATION, {
          name: `${value?.[0]} to ${value?.[1]}`,
          restInfo: { source },
        });
        break;

      case `showOnlyPreferredInstitutes`:
        CfEventUtil.triggerCfEvent(CFEventsType.SHOW_PREFERRED_ONLY, {
          restInfo: { source },
        });
        break;

      case `intakeStatus`:
        CfEventUtil.triggerCfEvent(CFEventsType.SHOW_OPEN_ONLY, {
          restInfo: { source },
        });
        break;
      case `intake`:
        CfEventUtil.triggerCfEvent(CFEventsType.TOP_FILTER_INTAKE, {
          restInfo: {
            name: value?.map((v: any) => v?.label)?.join(`, `),
            source,
          },
        });
        break;

      default:
        break;
    }
  } else if (
    Object.values(OtherFiltersMapping)
      .map((data) => data.key)
      .includes(name)
  ) {
    CfEventUtil.triggerCfEvent(CFEventsType.ADDITIONAL_FILTER, {
      name: startCase(toLower(name)),
    });
  }
};

export const removeDestinationFilters = (
  form: FormApi<CFFormType>,
  value: any,
) => {
  const destination = form.getState()?.values?.destination?.value;

  form.batch(() => {
    if (
      destination === value ||
      (Array.isArray(value) && value?.length === 0)
    ) {
      form.change(`destination`, undefined);
      form.change(`destinationCity`, undefined);
      form.change(`destinationCountry`, undefined);
      form.change(`destinationInstitute`, undefined);
      form.change(`destinationState`, undefined);
    }
  });
};

export const convertInputToReadableCurrencyFormat = (
  value: string | number,
  currencyCode: string,
) => {
  if ([`PKR`, `INR`].includes(currencyCode)) {
    return value?.toLocaleString(`en-IN`);
  }
  return value?.toLocaleString(`en-US`);
};

export const roundUpToNextNearestThousand = (value: number) => {
  if (typeof value !== `number`) return 0;
  return Math.ceil(value / 1000) * 1000;
};

export const roundDownToNextNearestThousand = (value: number) => {
  if (typeof value !== `number`) return 0;
  return Math.floor(value / 1000) * 1000;
};

export const INRCurrencyDetails = [
  {
    code: `INR`,
    default: true,
    conversionRate: 1,
    name: `Indian Rupee`,
    label: `INR`,
    value: `INR`,
  },
];

export const validateTuitionFees = (
  value: any,
  values: any,
  setIsMinError?: (isMinError: boolean) => void,
  setIsMaxError?: (isMaxError: boolean) => void,
) => {
  const min = Number(value);
  const max = Number(value);

  const conversionRate = values?.studentCurrency?.conversionRate;
  const maxTuitionFeesInLocalCurrency = Math.round(
    values?.maxCourseFeeInInr / conversionRate,
  );
  const convertedMaxFees = roundUpToNextNearestThousand(
    maxTuitionFeesInLocalCurrency,
  );
  const minTuitionFeesInLocalCurrency = Math.round(
    values?.minCourseFeeInInr / conversionRate,
  );
  const convertedMinFees = roundDownToNextNearestThousand(
    minTuitionFeesInLocalCurrency,
  );

  if (setIsMaxError) {
    if (
      isNaN(max) ||
      max < values?.tuitionFeesMin ||
      max > convertedMaxFees ||
      max < convertedMinFees
    ) {
      setIsMaxError(true);
      return true;
    }
    setIsMaxError(false);
    return null;
  } else {
    if (
      isNaN(min) ||
      min > values?.tuitionFeesMax ||
      min < convertedMinFees ||
      min > convertedMaxFees
    ) {
      setIsMinError?.(true);
      return true;
    }
    setIsMinError?.(false);
    return null;
  }
};

const removePunctuationMarks = (searchQuery: string) => {
  const punctuationMarks: string[] = [`.`, `/`];
  const filteredSearchQuery = isFalsy(searchQuery)
    ? ``
    : String(searchQuery)
        ?.split(``)
        ?.filter(
          (searchQueryCharacter: string) =>
            !punctuationMarks?.includes(searchQueryCharacter),
        )
        ?.join(``);
  return filteredSearchQuery;
};

const extractKeyWords = (
  searchQueryWithoutPunctuations: string,
  degreeMappingsWithLowerCaseKeys: Record<string, string | number>,
  stopWords: string[],
) => {
  const extractedKeyWords = searchQueryWithoutPunctuations
    ?.split(` `)
    ?.filter(
      (searchQueryCharacter: string) =>
        !Object.keys(degreeMappingsWithLowerCaseKeys).includes(
          searchQueryCharacter?.toLowerCase(),
        ),
    )
    ?.join(` `)
    ?.toLowerCase();
  const keywordWithoutStopwords = extractedKeyWords
    ?.split(` `)
    ?.filter((keyWord: string) => !stopWords?.includes(keyWord))
    ?.join(` `);
  return keywordWithoutStopwords;
};

const extractDegree = (
  searchQueryWithoutPunctuations: string,
  degreeMappingsWithLowerCaseKeys: Record<string, string | number>,
  degreeOptions: OPTION_TYPE[],
) => {
  const extractedDegrees = searchQueryWithoutPunctuations
    ?.split(` `)
    ?.filter((searchQueryCharacter: string) =>
      Object.keys(degreeMappingsWithLowerCaseKeys).includes(
        searchQueryCharacter.toLowerCase(),
      ),
    )
    ?.join(``)
    ?.toLowerCase();

  const extractedDegreeOptionType = (degreeOptions ?? [])?.find(
    (degree) =>
      degree?.value === degreeMappingsWithLowerCaseKeys[extractedDegrees],
  );
  return { extractedDegrees, extractedDegreeOptionType };
};

export const extractDegreeAndCourseName = (
  inputString: string | OPTION_TYPE,
  values: CFFilterPayload,
  stopWords: string[],
  degreeMappings: Record<string, string | number>,
  degreeOptions: OPTION_TYPE[],
) => {
  const enteredSearchQuery = isOptionType(inputString)
    ? (inputString?.value as string)
    : inputString;

  const degreeMappingsWithLowerCaseKeys: Record<string, string | number> = {};
  for (const degree of Object.keys(degreeMappings)) {
    degreeMappingsWithLowerCaseKeys[degree?.toLowerCase()] =
      degreeMappings[degree];
  }

  const searchQueryWithoutPunctuations =
    removePunctuationMarks(enteredSearchQuery);
  const degreeWordsPresentInQuery = searchQueryWithoutPunctuations
    ?.split(` `)
    ?.filter((word: string) => degreeMappingsWithLowerCaseKeys[word]);

  if (
    !values?.tags ||
    values?.tags?.value !== (values as any)?.tagsRawSearch?.value ||
    enteredSearchQuery?.includes(`.`) ||
    !isFalsy(degreeWordsPresentInQuery)
  ) {
    const keywordWithoutStopwords = extractKeyWords(
      searchQueryWithoutPunctuations,
      degreeMappingsWithLowerCaseKeys,
      stopWords,
    );

    const { extractedDegrees, extractedDegreeOptionType } = extractDegree(
      searchQueryWithoutPunctuations,
      degreeMappingsWithLowerCaseKeys,
      degreeOptions,
    );

    if (extractedDegrees || keywordWithoutStopwords) {
      const isExtractedDegreePresentInFilters = values?.degreeIdsV2?.find(
        (degree: OPTION_TYPE) =>
          degree?.value === extractedDegreeOptionType?.value,
      );

      let extractedDegreeIds;
      if (extractedDegreeOptionType && !isExtractedDegreePresentInFilters) {
        if (values?.degreeIdsV2) {
          extractedDegreeIds = [
            ...values?.degreeIdsV2,
            extractedDegreeOptionType,
          ];
        } else {
          extractedDegreeIds = [extractedDegreeOptionType];
        }
      } else {
        extractedDegreeIds = values?.degreeIdsV2;
      }

      const transformedTags = keywordWithoutStopwords;
      return { extractedDegreeIds, transformedTags };
    }
    return null;
  }

  return null;
};

export const disabledOption = (option: OPTION_TYPE) => {
  if (option?.count) {
    return option?.count == 0 ? true : false;
  }
  return true;
};
export const getModifiedGradeLabel = (option: OPTION_TYPE): OPTION_TYPE => {
  switch (option?.value as keyof typeof GRADING_SCHEME) {
    case `CGPA`:
      return { ...option, label: `${option.label} (scale out of 10)` };
    case `PERCENTAGE`:
      return { ...option, label: `${option.label} (scale out of 100)` };
    case `ZERO_TO_FOUR`:
      return { ...option, label: `${option.label} (scale out of 4)` };
    default:
      return option;
  }
};

export type UspNameType = definitions['UniversityUspData']['uspName'];

export const UspLabelImageMapper: Record<UspNameType, string | undefined> = {
  HIGH_OFFER_RATE: undefined,
  QUICKEST_OFFER_TAT: `fi fi-rr-time-fast`,
  GB_APP_FEE_WAIVER: `fi fi-rr-briefcase text`,
  HIGH_RANKING_INSTITUTE: `fi fi-rr-ranking-star`,
  PLACEMENT_SUPPORT: `fi fi-rr-briefcase text`,
  AFFORDABILITY: undefined,
  MOI_ACCEPTED: `fi fi-rs-assept-document`,
  POPULAR_AMONG_GEEBEE: `fi fi-rr-circle-star`,
  STUDENT_FAVOURITE_UNIVERSITY_FOR_APPLICATIONS: `fi fi-sr-heart text-error-600`,
  HIGHEST_ENROLLMENT_RATE: `fi fi-sr-plane-departure text-primary-700`,
};

// Function to create a set of unique objects based on a property or properties
export function createUniqueSet(array: any[], keyFunction: (arg0: any) => any) {
  const seen = new Set();
  return array.filter((obj: any) => {
    const key = keyFunction(obj);
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
}
export const generateDisplayOrderForUsps = (
  selectedFilters: string[],
  uspOptions?: definitions['UniversityUspResponseDto'][],
) => {
  try {
    const selectedUsps = uspOptions?.filter((uspData) => {
      if (uspData?.uspKey && selectedFilters?.includes(uspData?.uspKey)) {
        return uspData;
      }
    });
    const unSelectedUsps = uspOptions?.filter((uspData) => {
      if (uspData?.uspKey && !selectedFilters?.includes(uspData?.uspKey)) {
        return uspData;
      }
    });
    const uspsToDisplay = [...(selectedUsps || []), ...(unSelectedUsps || [])];
    return { uspsToDisplay, selectedUsps };
  } catch (error) {
    return {};
  }
};

const moveDegreeOptionsToLastAndMakeThemDisable = (
  degreeOptions: OPTION_TYPE[],
  degreeIdsToDisable: number[],
) => {
  // Separate the options that need to be disabled
  const disabledOptions = degreeOptions.filter((option) =>
    degreeIdsToDisable.includes(option.value as number),
  );

  // Remove the disabled options from the original array
  const filteredOptions = degreeOptions.filter(
    (option) => !degreeIdsToDisable.includes(option.value as number),
  );

  // Concatenate the filtered options and the disabled options
  const resultOptions = filteredOptions.concat(
    disabledOptions.map((d) => ({ ...d, isDisabled: true })),
  );

  return resultOptions;
};

export const redirectToUniversityPageWithFilters = (
  university: CfUniversitiesType[number],
  cfFilters?: CFFilterPayload,
  student?: any,
) => {
  if (!university?.id) return;

  const filters: any = {
    ...cfFilters,
    ...(student
      ? {
          student: { studentId: student?.value, studentName: student?.label },
        }
      : {}),
  };
  let encodedStudent: string | null = null;
  try {
    encodedStudent = encodeURIComponent(
      JSON.stringify({
        studentId: student?.value,
        studentName: student?.label,
      }),
    );
  } catch (error) {
    console.log(`Error in encoding student name`, error);
  } finally {
    window.open(
      `/university/${
        university?.id
      }?active=COURSES&filters=${encodeURIComponent(JSON.stringify(filters))}${
        !isFalsy(student?.value) ? `&student=${encodedStudent}` : ``
      }`,
      `_blank`,
    );
  }
};

type EuropeanCountriesHelperFnProps = {
  formSelectedCountryIds: number[];
  sharedListEuropeanCountries: any;
};

export const isEuropeanCountriesFilterApplied = ({
  formSelectedCountryIds,
  sharedListEuropeanCountries,
}: EuropeanCountriesHelperFnProps) => {
  return formSelectedCountryIds
    ?.map((countryId: any) => {
      return sharedListEuropeanCountries
        ?.map((item: any) => Number(item.labelKey))
        ?.includes(countryId);
    })
    ?.some((condition: any) => condition);
};

export const transformCourseCardsToAddProfileShortlisting = (props: {
  data: Array<{
    keyName: CFViewCourseType;
    keyValue: Courses;
  }>;
  config: InsertionConfigType;
}): Record<CFViewCourseType, Courses> => {
  const result: any = {};
  let totalCount = 0;
  let lastCategory:
    | {
        keyName: CFViewCourseType;
        keyValue: Courses;
      }
    | undefined;

  const nonInventoryCourses =
    props.data.find((item) => item.keyName === `nonInventoryCourses`)
      ?.keyValue ?? [];
  const allExceptNonInventoryCourses =
    props.data
      .filter((item) => item.keyName !== `nonInventoryCourses`)
      ?.map((localItem) => localItem.keyValue)
      ?.flat() ?? [];

  for (const category of props.data) {
    result[category.keyName] = [];
    for (const item of category.keyValue) {
      if (totalCount > 0) {
        Object.keys(props.config.keys)?.forEach((key: any) => {
          const repeatAfter =
            (props.config?.keys as any)?.[key]?.repeatAfter ??
            props.config.repeatAfter;

          const startIndex = (props.config.keys as any)?.[key]?.startIndex;

          if (startIndex <= repeatAfter) {
            const indexCheck = repeatAfter === startIndex ? 0 : startIndex;
            if (totalCount % repeatAfter === indexCheck) {
              result[category.keyName].push({
                type: key,
              });
            }
          } else {
            const indexCheck = startIndex % repeatAfter;
            if (
              totalCount >= startIndex &&
              totalCount % repeatAfter === indexCheck
            ) {
              result[category.keyName].push({
                type: key,
              });
            }
          }
        });
      }
      result[category.keyName].push(item);
      lastCategory = category;
      totalCount++;
    }
  }
  Object.keys(props.config.keys)?.forEach((key: any) => {
    const repeatAfter =
      (props.config?.keys as any)?.[key]?.repeatAfter ??
      props.config.repeatAfter;

    const startIndex = (props.config.keys as any)?.[key]?.startIndex;

    if (startIndex <= repeatAfter) {
      const indexCheck = repeatAfter === startIndex ? 0 : startIndex;
      if (
        lastCategory?.keyName &&
        (lastCategory?.keyValue?.length ?? 0) > 0 &&
        totalCount % repeatAfter === indexCheck
      ) {
        result[lastCategory?.keyName].push({
          type: key,
        });
      }
    } else {
      const indexCheck = startIndex % repeatAfter;
      if (
        lastCategory?.keyName &&
        (lastCategory?.keyValue?.length ?? 0) > 0 &&
        totalCount > startIndex &&
        totalCount % repeatAfter === indexCheck
      ) {
        result[lastCategory?.keyName].push({
          type: key,
        });
      }
    }
  });

  return result;
};
