import { Button, GBTypography } from '@leapfinance/geebee-component-library';
import { Close } from '@mui/icons-material';
import { Divider, IconButton } from '@mui/material';

import { MODAL_TYPES } from '@/components/Modals/GlobalModal';
import useModal from '@/components/Modals/useModal';
import {
  GBLocalStorageUtil,
  localStorageKeys,
} from '@/utils/GBLocalStorageUtil';

const IELTSAdoptionPopper = () => {
  const { hideModal } = useModal();

  const handleSaveIeltsPopperDisplayed = () => {
    const ieltsBannerDisplayedDates =
      GBLocalStorageUtil.handleReadLocalStorageData(
        localStorageKeys.IELTS_ADOPTION_POPUP_DISPLAYED,
      ) ?? [];
    const today = new Date()?.toISOString()?.split(`T`)?.[0];
    if (!ieltsBannerDisplayedDates?.includes(today)) {
      ieltsBannerDisplayedDates?.push(today);
    }
    GBLocalStorageUtil.handleSetLocalStorageData(
      localStorageKeys.IELTS_ADOPTION_POPUP_DISPLAYED,
      ieltsBannerDisplayedDates,
    );
  };

  return (
    <div className="flex justify-end items-end h-full">
      <div className="bg-white flex flex-col rounded-lg max-w-[500px] m-5  relative">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="https://ik.imagekit.io/v8frh8b7rf/IELTS%20GTM/ielts-adooption-background.png?updatedAt=1727677273933"
          width={`100%`}
          height={260}
          alt="representation"
        />
        <div className="py-5 px-6 flex flex-col gap-4">
          <div className="flex flex-col gap-1 text-center">
            <GBTypography
              variant="caption1"
              className="text-gbTokens-grey-A900"
            >
              Looking for an IELTS course for your business?
            </GBTypography>
            <GBTypography
              variant="body3Semibold"
              className="text-gbTokens-primary-600"
            >
              Introducing IELTS Self-Prep Kit!
            </GBTypography>
          </div>
          <GBTypography variant="body4">
            GeeBee Education has designed a white-labelled IELTS solution
            exclusively FREE for our partners. Offer students a tailored IELTS
            preparation course & increase their chances of admission.
          </GBTypography>
        </div>
        <Divider />
        <div className="flex gap-4 self-end py-3 px-4">
          <Button
            variant="tertiary"
            onClick={() => {
              window.open(`/ielts`);
              handleSaveIeltsPopperDisplayed();
            }}
          >
            Learn More
          </Button>
          <Button
            onClick={() => {
              window.open(`/rewards-club`);
              handleSaveIeltsPopperDisplayed();
            }}
          >
            Get Started
          </Button>
        </div>
        <IconButton
          className="absolute -top-3 -left-3 bg-white p-1"
          onClick={() => {
            hideModal(MODAL_TYPES.NO_RESULTS);
            handleSaveIeltsPopperDisplayed();
          }}
        >
          <Close className="text-black" />
        </IconButton>
      </div>
    </div>
  );
};

export default IELTSAdoptionPopper;
