import { GBLocalStorageUtil, localStorageKeys } from './GBLocalStorageUtil';

export const MODAL_DISBALE_DATE = `2024/1/31`;

// Helper function to get the current time in hours (24-hour format)
function getCurrentHour(): number {
  return new Date().getHours();
}

// Helper function to check if a given time falls within a specified range
function isTimeInRange(
  currentHour: number,
  startHour: number,
  endHour: number,
): boolean {
  return currentHour >= startHour && currentHour < endHour;
}

type ShouldShowPopUpParams = {
  key: localStorageKeys;
  skipTimeSlot: boolean;
  maxTimesToShow: number;
  minGapHours?: number;
};

// Function to check if a pop-up should be shown
export function shouldShowPopup({
  key,
  skipTimeSlot,
  maxTimesToShow,
  minGapHours,
}: ShouldShowPopUpParams): boolean {
  const minGapHoursLocal = minGapHours ?? 4;

  // Get user data from localStorage
  const lastPopupTimes: string[] =
    GBLocalStorageUtil.handleReadLocalStorageData(key) ?? [];
  const popupCount = lastPopupTimes.length;

  // If the user has seen the popup the maximum number of times, return false
  if (popupCount >= maxTimesToShow) {
    return false;
  }

  // Get the current time and the last pop-up time
  const currentHour = getCurrentHour();
  const lastPopupTime =
    popupCount > 0 ? new Date(lastPopupTimes[popupCount - 1]) : null;

  // Check if the current time is within the allowed range for showing the popup
  const isWithinFirstTimeSlot = isTimeInRange(currentHour, 11, 15);
  const isWithinSecondTimeSlot = isTimeInRange(currentHour, 16, 19);

  // Check if we are in an allowed time slot for showing the popup
  if (!skipTimeSlot && !(isWithinFirstTimeSlot || isWithinSecondTimeSlot)) {
    return false;
  }

  // Check if the minimum gap has passed since the last pop-up
  if (lastPopupTime) {
    const hoursSinceLastPopup =
      (new Date().getTime() - lastPopupTime.getTime()) / (1000 * 60 * 60);
    if (hoursSinceLastPopup < minGapHoursLocal) {
      return false;
    }
  }

  return true;
}

export const markModalShown = (key: localStorageKeys) => {
  // Update last popup times in localStorage
  const lastPopupTimes: string[] =
    GBLocalStorageUtil.handleReadLocalStorageData(key) ?? [];
  lastPopupTimes.push(new Date().toISOString());
  GBLocalStorageUtil.handleSetLocalStorageData(key, lastPopupTimes);
};
