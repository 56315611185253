import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import IELTSAdoptionPopper from '@/components/Tiering/IELTSAdoptionPopper';
import { selectUserDetails } from '@/features/user/userSlice';
import { Permissions } from '@/permissions/featuresList';
import {
  GBLocalStorageUtil,
  localStorageKeys,
} from '@/utils/GBLocalStorageUtil';
import { markModalShown, shouldShowPopup } from '@/utils/newsletterFeatureUtil';

import { MODAL_TYPES } from './Modals/GlobalModal';
import useModal from './Modals/useModal';

const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;

const AnnouncementWrapper = (props: { children: React.ReactNode }) => {
  const { showModal, hideModal } = useModal();
  const router = useRouter();

  const isPromoteInstituteBannerVisible = useAppSelector((state) =>
    selectUserDetails(state),
  )?.isPromotedInstituteCategoryEnabled;
  const isStudentUser =
    useAppSelector((state) => selectUserDetails(state))?.permissions?.[0] ===
    Permissions.STUDENT;

  const isInternationalCsp =
    useAppSelector((state) => selectUserDetails(state))?.cspZone ===
    `International`;

  useEffect(() => {
    if (isStudentUser || !router?.isReady) return;
    const routesWhereToNotShow = [
      `/`,
      `/login`,
      `/changepwd`,
      `/resetpwd`,
      `/test-prep/video-course`,
      `/test-prep/video-course/[slug]`,
      `/test-prep/mock-test`,
      `/test-prep/mock-test/[mock_test_id]`,
      `/test-prep/mock-test/result/[mock_test_id]`,
      `/test-prep/mock-test/speaking/result/[id]`,
      `/ielts`,
      `/student-login`,
    ];

    // Display IELTS adoption popper first
    const ieltsBannerDisplayedDates =
      GBLocalStorageUtil.handleReadLocalStorageData(
        localStorageKeys.IELTS_ADOPTION_POPUP_DISPLAYED,
      ) ?? [];
    const today = new Date()?.toISOString()?.split(`T`)?.[0];

    if (
      !routesWhereToNotShow?.includes(router?.pathname?.toString()) &&
      !ieltsBannerDisplayedDates?.includes(today) &&
      ieltsBannerDisplayedDates?.length < 3
    ) {
      showModal({
        modalType: MODAL_TYPES.NO_RESULTS,
        modalProps: {
          body: <IELTSAdoptionPopper />,
        },
      });
      GBLocalStorageUtil.handleSetLocalStorageData(
        localStorageKeys.IELTS_POPUP_DISPLAYED_TIME,
        new Date(),
      );
      return;
    }

    // Display GB Loans modal next only after 2 hours from IELTS popup display
    const showLoansUpsellModal = shouldShowPopup({
      key: localStorageKeys.SHOW_LOANS_UPSELL_COUNT,
      maxTimesToShow: 3,
      skipTimeSlot: true,
    });

    const lastIeltsPopupDisplayedTime =
      GBLocalStorageUtil?.handleReadLocalStorageData(
        localStorageKeys.IELTS_POPUP_DISPLAYED_TIME,
      ) ?? new Date();
    const currentTime = new Date();

    const timeElapsedFromLastIeltsPopupDisplay =
      (currentTime as any) - (new Date(lastIeltsPopupDisplayedTime) as any);

    if (
      showLoansUpsellModal &&
      !routesWhereToNotShow?.includes(router?.pathname?.toString()) &&
      ![`/rewards-club`]?.includes(router?.pathname?.toString()) &&
      !isInternationalCsp &&
      timeElapsedFromLastIeltsPopupDisplay > twoHoursInMilliseconds
    ) {
      showModal({
        modalType: MODAL_TYPES.LOANS_REQUEST_UPSELL,
        modalProps: {
          onClose: () => {
            markModalShown(localStorageKeys.SHOW_LOANS_UPSELL_COUNT);
            hideModal();
          },
        },
      });
      return;
    }

    // Display Commissions modal next
    // const showPromotedInstituteModal = shouldShowPopup({
    //   key: localStorageKeys.LAST_POPUP_TIMES,
    //   maxTimesToShow: 5,
    //   skipTimeSlot: false,
    // });

    // if (showPromotedInstituteModal && isPromoteInstituteBannerVisible) {
    //   showModal({
    //     modalType: MODAL_TYPES.NEWSLETTER_ANNOUNCEMENT,
    //     modalProps: {
    //       onClose: () => {
    //         markModalShown(localStorageKeys.LAST_POPUP_TIMES);
    //         hideModal();
    //       },
    //     },
    //   });
    //   return;
    // }
  }, [router, isInternationalCsp, isStudentUser]);

  return <>{props.children}</>;
};

export default AnnouncementWrapper;
